jQuery.fn.imageResponsive = function () {
  return this.each(function () {
    var img = jQuery(this).find("img"),
        defaultWidth = img.prop('naturalWidth'),
        defaultHeight = img.prop('naturalHeight'),
        parentHeight = jQuery(this).height(),
        parentWidth = jQuery(this).width(),
        aspectRatio = defaultWidth / defaultHeight;
    img.css({
      "height": "auto",
      "width": "100%",   
      "margin-left": "0px",
      "max-width": "inherit"
    });
    var imgHeight = parentWidth / aspectRatio;   
    var imgTop = (imgHeight - parentHeight) / 2;
    img.css({
      "margin-top": "-" + imgTop + "px"
    });
    if (img.height() < parentHeight) {
      img.css({
        "height": "100%",
        "width": "auto"
      });
      var right_margin = (img.width() - parentWidth) / 2;
      img.css({
        "margin-left": "-" + right_margin + "px",
        "margin-top": "0"
      });
    }
    else if (img.width() < parentWidth) {
      img.css({
        "height": "auto",
        "width": "100%",
        "margin-left": "0"
      });
      img.css({
        "margin-top": "-" + imgTop + "px"
      });
    }
  });
};

jQuery.fn.sameHeight = function () {
  var maxHeight = 0;
  return this.each(function (index, elem) {
    jQuery(elem).height('auto');
    var boxHeight = jQuery(elem).height();
    maxHeight = Math.max(maxHeight, boxHeight);
  }).height(maxHeight);
};

